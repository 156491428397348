.row-grid {
	width: 100%;
	display: grid;
	grid-gap: 30px;

	&.grid-gap-60 {
		grid-gap: 60px;
	}

	&.grid-gap-40 {
		grid-gap: 40px;
	}

	&.grid-gap-20 {
		grid-gap: 20px;
	}

	&.grid-gap-15 {
		grid-gap: 15px;
	}

	&.grid-gap-10 {
		grid-gap: 10px;
	}

	&.grid-gap-5 {
		grid-gap: 5px;
	}

	&.no-gap {
		grid-gap: 0;
	}

	&.row-gap-0 {
		row-gap: 0;
	}

	&.column-gap-0 {
		column-gap: 0;
	}

	&.column-gap-60 {
		column-gap: 60px;
	}

	&.col-auto-100 {
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
		// grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	}

	&.col-auto-150 {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	}

	&.col-auto-200 {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	&.col-auto-300 {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}

	&.col-auto-400 {
		grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	}

	&.col-auto-500 {
		grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	}

	&.col-2 {
		grid-template-columns: repeat(2, 1fr);
	}

	&.col-3 {
		grid-template-columns: repeat(3, 1fr);
	}

	&.col-4 {
		grid-template-columns: repeat(4, 1fr);
	}

	&.col-5 {
		grid-template-columns: repeat(5, 1fr);
	}

	&.col-md-2 {
		@include media('>=tablet') {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&.col-md-3 {
		@include media('>=tablet') {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&.col-md-4 {
		@include media('>=tablet') {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&.col-md-5 {
		@include media('>=tablet') {
			grid-template-columns: repeat(5, 1fr);
		}
	}

	&.col-lg-2 {
		@include media('>=desktop') {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&.col-lg-3 {
		@include media('>=desktop') {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&.col-lg-4 {
		@include media('>=desktop') {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&.col-lg-5 {
		@include media('>=desktop') {
			grid-template-columns: repeat(5, 1fr);
		}
	}

	&.col-xl-2 {
		@include media('>=widescreen') {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&.col-xl-3 {
		@include media('>=widescreen') {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&.col-xl-4 {
		@include media('>=widescreen') {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&.col-xl-5 {
		@include media('>=widescreen') {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}


.d-flex {
	display: flex;
}

.d-flex-md {

	@include media('>=tablet') {
		display: flex;
	}
}

.d-none {
	display: none;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.w-100 {
	width: 100% !important;
}

.w-50 {
	width: 50% !important;
}

.w-100px {
	width: 100px;
}

.w-50px {
	width: 50px;
}

.min-w-100px {
	min-width: 100px;
}

.min-w-50px {
	min-width: 50px;
}

.h-100 {
	height: 100%;
}

.h-100vh {
	height: 100vh;
}

.h-100px {
	height: 100px;
}

.h-200px {
	height: 200px;
}


.height-auto {
	height: auto !important;
}

.clear-css-prop {
	all: inherit;
}

.white-space-wrap {
	white-space: normal !important;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.fz-20 {
	font-size: 20px;
}

.fz-30 {
	font-size: 30px;
}

.fz-50 {
	font-size: 50px;
}

.fz-70 {
	font-size: 70px;
}

.fz-100 {
	font-size: 100px;
}

.fz-200 {
	font-size: 200px;
}


.mt-0 {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}